/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'stats-daily-chart'
  },
  {
    path: 'room-order-stat',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/room-order-stat/room-order-stat.module').then(m => m.RoomOrderStatModule)
  },
  {
    path: 'naver-powerlink',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/naver-powerlink/naver-powerlink.module').then(m => m.NaverPowerlinkModule)
  },
  {
    path: 'stats-daily-order',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/stats-daily-order/stats-daily-order.module').then(m => m.StatsDailyOrderModule)
  },
  {
    path: 'stats-daily-order-chart',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/stats-daily-order-chart/stats-daily-order-chart.module').then(m => m.StatsDailyOrderChartModule)
  },
  {
    path: '**',
    redirectTo: 'stats-daily-order-chart'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
