/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ConfVendor } from '../../schema/1/schema-common';
import { IndexConf, IndexConfItem, PrinterConf } from '../../schema/3/schema-ops';

import { UtilService } from '../1/util.service';

const confCollectionPath = 'conf';

@Injectable({
  providedIn: 'root'
})
export class ConfService {
  printerConf: PrinterConf = {};
  latestPrinterConfSubject = new BehaviorSubject<PrinterConf>({});
  printerSubscription: Subscription;

  indexConf: {
    [vendor: string]: IndexConf
  } = {};
  latestIndexConfSubject: {
    [vendor: string]: BehaviorSubject<IndexConf>;
  } = {};
  indexSubscription: {
    [vendor: string]: Subscription;
  } = {};

  constructor(
    private db: AngularFirestore,
    private util: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observePrinterConf() {
    const docRef = this.db.doc<PrinterConf>(`${confCollectionPath}/printer`);

    this.printerSubscription = docRef.valueChanges().subscribe(doc => {
      this.printerConf = doc;
      this.latestPrinterConfSubject.next(doc);
      // console.dir(printer);
    }, error => {
      this.util.toastrError(`observePrinterConf에서 에러 발생 : ${error}`);
    });
  }

  observeIndexConf(vendor: ConfVendor) {
    const docRef = this.db.doc<IndexConf>(`${confCollectionPath}/index-${vendor}`);

    this.latestIndexConfSubject[vendor] = new BehaviorSubject<IndexConf>({});

    this.indexSubscription[vendor] = docRef.valueChanges().subscribe(doc => {
      this.indexConf[vendor] = doc;
      this.latestIndexConfSubject[vendor].next(doc);
    }, error => {
      this.util.toastrError(`observeIndexConf에서 에러 발생 : ${error}`);
    });
  }

  observeIndexConfs() {
    for (const vendor of (['baemin', 'foodfly', 'vroong'] as ConfVendor[])) {
      this.observeIndexConf(vendor);
    }
  }

  indexConfForInstanceId(vendor: ConfVendor, instanceId: string): IndexConfItem {
    if (this.indexConf[vendor] && this.indexConf[vendor][instanceId]) {
      return this.indexConf[vendor][instanceId];
    } else {
      return {
        name: '',
        organization: '',
        site: '',
        room: ''
      };
    }
  }
}
