/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UtilService } from './core/1/util.service';
import { LocalConfigurationService } from './core/1/local-configuration.service';
import { debugLog } from './core/1/common';
import { VersionService } from './core/1/version.service';
import { AuthService } from './core/2/auth.service';
import { sleep } from './core/2/util';
import { UserService } from './core/2/user.service';
import { ConfService } from './core/2/conf.service';
import { RoomService } from './core/3/room.service';
import { SiteService } from './core/3/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  email = '';
  myVersion: string;
  latestVersion: string;
  duration = 0;
  currentNavItemName: string;

  public showDurationFilter: boolean;

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;
  private durationSubscription: Subscription = null;

  title = '발가락 그리드';
  navItems = [
    { name: '지점별 매출 차트', route: '/stats-daily-order-chart', durationFilter: 'hide' },
    { name: '일별 주문 통계', route: '/stats-daily-order', durationFilter: 'hide' },
    { name: '일별 주문 통계 상세', route: '/room-order-stat', durationFilter: 'hide' },
    { name: 'divider' },
    { name: '네이버 파워링크 순위', route: '/naver-powerlink', durationFilter: 'hide' },
    { name: 'divider' },
  ];

  // 최초 접속한 경로명('/stats-daily-order-chart')를 기억한다.
  // '/'로 접속할 경우에는 바로 redirect로 다른 곳으로 이동하게 되고,
  // 리로드를 할 경우에 브라우저는 변경된 URL에 대해서 cache를 업데이트한다.
  // 이럴 경우에 '/'로 접속을 하면 '/'에 대한 캐시는 이전 버전이기 때문에 캐시 만료전까지 업데이트 버튼이 보이는 문제가 있다.
  // 이를 해결하기 위해서 최초 접속 경로명이 '/'인 경우에는 pushState()를 이용해서 '/'로 변경 후에 리로드를 해서 '/'에 대한 캐시가 변경이 되게 한다.
  initialPathname = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private confService: ConfService,
    private util: UtilService,
    private roomService: RoomService,
    private siteService: SiteService,
    private versionService: VersionService,
    private localConfigurationService: LocalConfigurationService
  ) {
    this.initialPathname = window.location.pathname;
    console.log(`initialPathname = ${this.initialPathname}`);

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        for (const navItem of this.navItems) {
          if (navItem.route === event.urlAfterRedirects) {
            this.currentNavItemName = navItem.name;
            document.title = navItem.name;
            this.showDurationFilter = navItem.durationFilter !== 'hide';
            break;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      })
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);
      this.loggedIn = value;
      if (value === true) {

        this.util.toastrInfo('로그인 성공!', null, 3000);
        this.email = this.authService.user.email;

        // 아래 3가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        // TODO: 로그아웃 후에 기존의 데이터를 초기화하도록 확인한다.
        this.userService.observe(this.email);

        // await promiseForInit()를 실행한 경우에 UI의 반응이 떨어지는 듯하여 아래와 같이 변경
        // 정밀한 분석 필요
        while (this.userService.user === undefined) {
          debugLog('Waiting for the first sync with Firestore user');
          await sleep(200);
        }

        this.confService.observeIndexConfs();
        this.siteService.observe();
        this.roomService.observe();
      }

      // subscribe로 변경하면서 아래의 문제가 저절로 해결된다.
      // refer: https://stackoverflow.com/questions/35105374/how-to-force-a-components-re-rendering-in-angular-2
      // LoginComponent가 붙을 경우에 별다른 일을 하지 않으니 AppComponent의 뷰가 갱신되지 않았다.
      // this.changeDetectorRef.detectChanges();
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });
    this.durationSubscription = this.localConfigurationService.durationSubject.subscribe(duration => {
      this.duration = duration;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.versionSubscription?.unsubscribe();
    this.durationSubscription?.unsubscribe();
  }

  logout() {
    this.authService.signOut().then(value => {
      // this.dialogLoginSevice.openLoginDialog();
      this.router.navigate(['/auth/login']);
    });
  }

  onChangeDuration(ev: Event) {
    let newDuration = parseInt((ev.target as HTMLInputElement).value, 10);

    if (newDuration > 8) {
      this.util.toastrInfo('최대 조회기간은 8일입니다.');
    }

    newDuration = Math.min(newDuration, 8);
    (ev.target as HTMLInputElement).value = `${newDuration}`;

    this.localConfigurationService.durationSubject.next(newDuration);
  }

  addOneDay() {
    const newDuration = parseInt((document.getElementById('duration') as HTMLInputElement).value, 10);

    if (newDuration === 8) {
      this.util.toastrInfo('최대 조회기간은 8일입니다.');
    } else {
      this.localConfigurationService.durationSubject.next(newDuration + 1);
    }
  }

  removeOneDay() {
    const newDuration = parseInt((document.getElementById('duration') as HTMLInputElement).value, 10);

    if (newDuration === 1) {
      this.util.toastrInfo('최소 조회기간은 1일입니다.');
    } else {
      this.localConfigurationService.durationSubject.next(newDuration - 1);
    }
  }

  reload() {
    // 앞에서 설명
    if (this.initialPathname === '/') {
      console.log('업데이트가 /에 대해서 되도록 location을 변경합니다.');
      window.history.pushState('initial', '', '/');
    }
    window.location.reload();
  }

}
