<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <ng-container *ngFor="let navItem of navItems">
        <div *ngIf="navItem.name !== 'divider'">
          <a *ngIf="navItem.route != '/auth/signup' || loggedIn == false"
            mat-list-item
            (click)="sidenav.close()"
            routerLinkActive
            #routerLinkActiveInstance="routerLinkActive"
            [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
            [routerLink]="[navItem.route]"
            >
            {{navItem.name}}
          </a>
        </div>
        <mat-divider *ngIf="navItem.name === 'divider'"></mat-divider>
      </ng-container>
      <mat-divider></mat-divider>
      <a mat-list-item href="javascript:window.location.reload();">Reload</a>
    </mat-nav-list>
  </mat-sidenav>
  <div>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="sidenav.open('mouse')">
        <mat-icon>menu</mat-icon>
      </button>
      <h1><span class='hideUnder768Width'>{{title}} {{this.currentNavItemName ? '- ' : ''}}</span> {{this.currentNavItemName}}</h1>
      <div class="right">
        <ng-container *ngIf="showDurationFilter">
          <span class="hideUnder768Width">조회 기간(일) : </span>
          <button mat-icon-button (click)="removeOneDay()"><mat-icon>remove_circle_outline</mat-icon></button>
          <input id="duration" type="number" min="1" max="120" step="1" [value]="duration" (change)="onChangeDuration($event)" required>
          <button mat-icon-button (click)="addOneDay()"><mat-icon>add_circle_outline</mat-icon></button>
        </ng-container>

        <button mat-raised-button *ngIf="myVersion != latestVersion" color="accent" (click)="reload()">업데이트</button>

        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>help</mat-icon></button>
        <mat-menu class="menu-buttons" #menu="matMenu">
          <span mat-menu-item *ngIf="loggedIn == true">{{ email }}</span>
          <mat-divider></mat-divider>
          <span mat-menu-item class="version">현재 버전:{{myVersion}}</span>
          <span mat-menu-item class="version" *ngIf="myVersion != latestVersion">최신 버전:{{latestVersion}}</span>
          <span mat-menu-item *ngIf="loggedIn == true" (click)="logout()">로그아웃</span>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
