/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제'
};

export const unifiedOrderVendorMappings = {
  baemin:       '배민',
  foodfly:      '푸플',
  yogiyo:       '요기요',
  ubereats:     '우버이츠',
  ddingdong:    '띵동',
  ddangyo:      '땡겨요',
  coupangeats:  '쿠팡이츠',
  shuttle:      '셔틀',
  ghostkitchen: '고스트키친',
  other:        '기타',
};

export const unifiedOrderChannelMappings = {
  app:  '앱',
  tel:  '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY:  '배달',
  TAKEOUT:   '테이크아웃',
  FOODFLY:   '푸드플라이',
  BAERA:     '배라',
  DDINGDONG: '띵동',
  UBER:      '우버이츠',
  COUPANG:   '쿠팡이츠',
  SHUTTLE:   '셔틀',
  HERE:      '매장식사'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED:    '신규',
  ACCEPTED:  '주문접수',
  CLOSED:    '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED:  '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED:  '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const foodflyAcceptStatusMappings = {
  0:  '신규',
  3:  '주문접수',
  5:  '조리완료',
  98: '취소',
  99: '주문거절'
};

export const foodflySendStatusMappings = {
  1: '배차대기',
  2: '배차완료',
  3: '업장도착',
  4: '픽업',
  5: '배달완료',
  6: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const centrexCallbackKindMappings = {
  1: '전화',
  2: 'SMS'
};

export const kiccNotiTypeMappings = {
  10: '승인',
  20: '변경',
  30: '입금',
  50: '환불(일반)'
};

export const kiccPayTypeMappings = {
  '01': '후불현금',
  '02': '후불카드',
  11: '신용카드',
  21: '계좌이체',
  22: '가상계좌',
  31: '휴대폰',
  50: '선불결제',
  60: '간편결제',
  81: '배치인증'
};
