/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { SiteDoc, SiteDocs } from '../../schema/3/schema';

import { UserService } from '../2/user.service';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  sites: SiteDocs = {};
  latestSubject = new BehaviorSubject<SiteDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
  ) {
  }

  observe() {
    // TODO: na 통계도 함께 보기 위해서 일단 제약을 두지 않는다.
    // const queryFn: QueryFn = ref => {
    //   return ref.where('organization', '==', this.userService.user.organization);
    // };
    // const collection = this.db.collection<SiteDoc>('site', queryFn);

    const collection = this.db.collection<SiteDoc>('site');
    collection.valueChanges().subscribe(docs => {
      this.sites = docs.reduce((acc, doc) => { acc[doc._id] = doc; return acc; }, {});
      this.latestSubject.next(this.sites);
    });
  }
}
