/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

import { UserDoc } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';

const collectionPath = 'user';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserDoc;
  latestUserSubject = new BehaviorSubject<UserDoc>(null);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe(email: string) {
    console.log(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`${collectionPath}/${email}`);

    this.subscription = docRef.valueChanges().subscribe(doc => {
      this.user = doc;

      this.latestUserSubject.next(doc);
    }, error => {
      this.utilService.toastrCatch(error, 'UserService:observe에서 에러 발생');
    });
  }
}
