/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { VersionDoc } from '../../schema/3/schema';

import { environment } from '../../../environments/environment';

const myVersion = environment.version;
const versionDocPath = 'conf/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  // tslint:disable-next-line: variable-name
  _latestVersion = myVersion;
  isDifferent = false;
  latestVersionSubject = new BehaviorSubject<string>(this._latestVersion);

  constructor(private db: AngularFirestore) {
    this.observeVersion();
  }

  get myVersion() {
    return environment.version;
  }

  get latestVersion() {
    return this._latestVersion;
  }

  /**
   *
   */
  observeVersion() {
    const doc = this.db.doc<VersionDoc>(versionDocPath);

    doc.valueChanges().subscribe(version => {
      this._latestVersion = version.gridVer;
      console.dir(`lateast version = ${this.latestVersion}`);
      this.latestVersionSubject.next(this._latestVersion);
    });
  }
}
